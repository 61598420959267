import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import _has from 'lodash/has'
import formatTitleUrl from '../../../../plugins/filters/formatTitleUrl/formatTitleUrl'
import { playChecks, redirectToFipChecks } from '../../../../middleware/checkups'

export default {
  computed: {
    ...mapGetters({
      profile: 'core/getProfile',
      userApplication: 'user/getApplication',
      timeOffset: 'user/getTimeOffset'
    })
  },
  methods: {
    /**
     * Essaye de lire le flux (zap) s'il est en cours de diffusion
     * Sinon redirige vers la fip ou la boutique selon les cas
     * @param {Object} channel
     * @param {Object} program
     */
    redirectToBoutiqueFipOrPlay (channel, program, confetti) {
      const bypassCheck = _has(program, 'id') // no id = no current program
      // Si le programme est en cours de diffusion ou il n'y a pas de programme défini alors on execute ZAP
      if ((this.isStreaming(program) || !bypassCheck) && channel) {
        this.play(channel)
      } else {
        this.redirectToFipOrBoutique(channel, program.id, program.title, confetti)
      }
    },
    /**
     * Effectue le zap (test authent + test bouquet) et lance le player si OK
     * Sinon => Redirection mire authent ou boutique
     * @param {Object} channel
     */
    play (channel) {
      playChecks(this.$store, this.profile, this.userApplication, channel, this.$config.confetti)
        .then(() => this.$root.$emit('setPlayerChannel', channel))
        .catch(this.redirectTo)
    },
    /**
     * Redirige sur la FIP Si authentifié et bouquets de la chaine
     * Sinon Boutique
     * Pas de redirection mire authent
     * @param {Object} channel
     * @param {Number} programId
     * @param {String} programTitle
     * @param confetti
     */
    redirectToFipOrBoutique (channel, programId, programTitle, confetti) {
      redirectToFipChecks(this.$store, this.profile, channel, confetti)
        .then(() => this.redirectToFip(programId, formatTitleUrl(programTitle)))
        .catch(this.redirectTo)
    },
    /**
     * Redirect to fip with params required
     * @param programId
     * @param programTitle
     */
    redirectToFip (programId, programTitle) {
      this.$router.push({
        name: 'Fip',
        params: { programId, programTitle }
      })
    },
    /**
     * Redirect to url in param with option to open in new tab
     * @param url
     */
    redirectTo (link) {
      if (link && link.url) {
        window.open(link.url.href, link.tab ? '_blank' : '_self')
      }
    },
    /**
     * Le programme est il en cours de lecture ?
     * @param {Object} program
     */
    isStreaming (program) {
      const now = DateTime.local()
      const realTime = now.ts - this.timeOffset
      return (realTime > program.diffusionDate * 1000) && (realTime < (program.diffusionDate + program.duration) * 1000)
    }
  }
}
