<template>
  <div class="select-wrapper">
    <span
      ref="btnMore"
      :class="['ob1-filterchip', { 'ob1-filterchip-selected': filterOn }]">
      <button
        :aria-label="moreButtonLabel"
        class="ob1-filterchip-label ob1-filter-chips-bar-btn"
        type="button"
        @click="showFilters">
        {{ `${$t('general.thematiques.filters')} ${numberSelected ? '(' + numberSelected + ')' : ''}` }}
      </button>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FilterSelector',
  props: {
    genres: {
      type: Array,
      required: true
    },
    trackPage: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      Selectedfilters: 'genres/getSelectedFilters'
    }),
    numberSelected () {
      return this.Selectedfilters.length
    },
    filterOn () {
      return this.numberSelected > 0
    },
    moreButtonLabel () {
      return 'Plus de filtres'
    }
  },
  methods: {
    showFilters () {
      this.$root.$emit('showFilters', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-wrapper {
  display: flex;
  justify-content: flex-end;

  .small-select {
    /deep/ .thematique-item {
      background: transparent;
      width: 120px;
      height: 30px;
      position: relative;
    }

    select {
      -webkit-appearance: none;
    }

    &.selected {
      /deep/ .thematique-item {
        width: 150px;
      }
      .select-label {
        color: $white;
        background-color: $primary;
      }

      &.thematique-item {
        &:hover,
        &:focus {
          border: none;
        }
      }
    }

    .select-label {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
      top: 0;
      left: 0;
      background: $white;
      color: $black;
      font-size: 14px;
      border-radius: 20px;
      width: 100%;
      height: 100%;
      pointer-events: none;

      span {
        margin-left: 5px;
      }

      .filter-icon {
          margin-right: 5px;
      }
    }
  }
}
</style>
