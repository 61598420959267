<template>
  <div class="thematics padding">
    <filter-selector
      :genres="genres"
      :track-page="$stats.utils.getTrackPage(page)"
      class="hide-from-medium" />
    <wptv-chips-bar
      :items="filtersItems"
      :checked-items-list="checkedItems"
      :one-line="true"
      :track-page="$stats.utils.getTrackPage(page)"
      class="show-from-medium"
      @chipsbar-more-button-clicked="openModal"
      @chipsbar-list-updated="updateList" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import FilterSelector from './filterSelector/FilterSelector'
import { WptvChipsBar } from '@wptv/components/ui/chipsBar'
import { formatedGenre } from '../../../shared/utils/formatGenre.utils'

export default {
  name: 'Thematique',
  directives: {
    ClickOutside
  },
  components: {
    WptvChipsBar,
    FilterSelector
  },
  data () {
    return {
      filterChipAll: 'filter-chip-all',
      checked: ['filter-chip-all']
    }
  },
  computed: {
    ...mapGetters({
      formatedGenres: 'genres/getFormatedGenres',
      filters: 'genres/getSelectedFilters',
      genres: 'genres/getGenres',
      page: 'meta/getPage'
    }),
    filtersItems () {
      return [{ id: 'all', label: 'Tous' }, ...this.genres.map((e) => { return { id: `${e}`, label: e } })]
    },
    checkedItems () {
      return [...this.filters].length > 0 ? [...this.filters.map(e => `filter-chip-${e}`)] : this.checked
    }
  },
  mounted () {
    if (!this.$route.query.filtres) {
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, filtres: 'all' } })
    }
  },
  methods: {
    /**
     * Update the selected filters list and manage the filter all checking
     * @param selectedList
     */
    updateList (selectedList) {
      let checked
      const chipAllIndex = selectedList.indexOf(this.filterChipAll)
      if (selectedList.length === 0 || (selectedList.length === 1 && chipAllIndex === 0) || selectedList[selectedList.length - 1] === this.filterChipAll) {
        checked = [this.filterChipAll]
      } else if (selectedList.includes(this.filterChipAll)) {
        checked = selectedList.slice(0, chipAllIndex).concat(selectedList.slice(chipAllIndex + 1))
      } else {
        checked = selectedList
      }
      checked = checked.map(e => e.slice(12))
      let queryFilters = checked.map(e => formatedGenre(e))
      queryFilters = queryFilters.filter(e => e !== undefined).join()
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, filtres: queryFilters } })
      this.$store.commit('genres/setSelectedFilters', checked)
    },
    openModal () {
      this.$root.$emit('showFilters', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .thematics {
    margin-top: 30px;
  }
</style>
