<template>
  <div class="info-container">
    <!-- Program detail -->
    <router-link
      v-if="program && program.id"
      v-tealium:click="stats"
      :to="{
        name: 'Fip',
        params: { programId: program.id, programTitle: formattedTitle }
      }"
      data-wptv="mosaique-item-detail"
      class="fip-link">
      <div
        :aria-hidden="altOverride"
        class="title ellipsis"
        role="heading"
        aria-level="3"
        data-wptv="mosaique-item-title">
        {{ program.isEpisode ? getSerieTitle : title }}
      </div>

      <div
        v-if="genre"
        :aria-hidden="altOverride"
        class="genre ellipsis"
        data-wptv="mosaique-item-genre">
        {{ genre }}
      </div>

      <div
        v-if="program.diffusionDate && program.duration"
        :aria-hidden="altOverride"
        class="diffusion ellipsis"
        data-wptv="mosaique-item-diffusion">
        {{ remainingTime || diffusion }}
      </div>
      <span v-if="altOverride" class="sr-only">{{ altText }}</span>
    </router-link>

    <!-- no program title -->
    <div v-if="!program || !program.id" aria-hidden="true">
      <div
        class="title"
        data-wptv="mosaique-item-title">
        {{ $t('general.items.noTitle') }}
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { formatSeriesInfo } from '../../../shared/formatEpisode/formatEpisode.utils'
import formatTitleUrl from '../../../../plugins/filters/formatTitleUrl/formatTitleUrl'

export default {
  name: 'ItemInformations',
  props: {
    program: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    remainingTime: {
      type: String,
      default: null
    },
    stats: {
      type: Object,
      default: () => {}
    },
    altText: {
      type: String,
      default: null
    }
  },
  computed: {
    /**
     * Get diffusion information
     * @returns {string}
     */
    diffusion () {
      const start = this.$options.filters.conversionTimestamp(this.program.diffusionDate)
      const end = this.$options.filters.conversionTimestamp(this.program.diffusionDate + this.program.duration)

      return `${start} - ${end}`
    },
    /**
     * Get program genre
     * @returns {*}
     */
    genre () {
      let genre = ''

      if (this.program.programType === 'EPISODE') {
        genre = formatSeriesInfo(this.program)
      } else {
        genre = _get(this.program, 'genre', '')
      }

      return genre
    },
    getSerieTitle () {
      return this.program.season?.serie?.title
    },
    formattedTitle () {
      return formatTitleUrl(_get(this.program, 'title', null)) || ''
    },
    altOverride () {
      return this.altText !== null
    }
  }
}
</script>

<style scoped lang="scss">
  .info-container {
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    .fip-link {
      width: 100%;

      &:hover {
        text-decoration: none;
        color: $black;
      }

      @extend %focus-visible;

      &:focus {
        outline-offset: -2px;
      }
    }

    .ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
        overflow: hidden;
    }

    .genre,
    .diffusion {
      @include mediaQueries('font-size', (xsmall: 12px, large: 14px));
      @include mediaQueries('line-height', (xsmall: 16px, large: 18px));
    }

    .genre {
      font-weight: 700;
      color: $textgrey;
    }

    .diffusion {}

    .title {
      @include mediaQueries('font-size', (small: 1.143rem, medium: 1rem, large: 16px, xlarge: 18px));
      font-weight: 700;
    }
  }
</style>
