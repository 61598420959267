<template>
  <section :class="['main-header', { 'sticked': sticked }]">
    <div class="content">
      <main-menu
        :sticked="sticked"
        :routes="routes"
        role="navigation"
        class="main-menu" />
    </div>
  </section>
</template>

<script>
import MainMenu from '../../../shared/mainMenu/MainMenu'

export default {
  name: 'MainHeader',
  components: {
    MainMenu
  },
  props: {
    page: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      sticked: false,
      routes: [
        {
          name: this.$t('general.menu.home'),
          to: 'HomePage',
          aria: this.$t('general.menu.home'),
          dataWptv: 'menu-maintenant',
          stats: {
            track_page: 'home',
            track_zone: 'menu',
            track_nom: 'maintenant'
          }
        },
        {
          name: this.$t('general.menu.evening'),
          to: 'Evening',
          aria: this.$t('general.menu.evening'),
          dataWptv: 'menu-ce_soir',
          stats: {
            track_page: 'home',
            track_zone: 'menu',
            track_nom: 'ce_soir'
          }
        },
        {
          name: this.$t('general.menu.all'),
          to: 'Programs',
          aria: this.$t('general.menu.all'),
          dataWptv: 'menu-tous_les_programmes',
          stats: {
            track_page: 'home',
            track_zone: 'menu',
            track_nom: 'programme_tv'
          }
        },
        {
          name: this.$t('general.menu.recordings'),
          to: 'AvailableRecordings',
          aria: this.$t('general.menu.recordings'),
          dataWptv: 'menu-mes_enregistrements',
          icon: 'record-services',
          stats: {
            track_page: this.$stats.utils.getTrackPage(this.page),
            track_zone: 'mes_enreg',
            track_nom: 'lien'
          }
        }
      ]
    }
  },
  mounted () {
    // detecting menu sticked to add class sticked
    // /!\ CAUTION : EXPERIMENTAL FEATURE
    // See compliance here -> https://developer.mozilla.org/fr/docs/Web/API/Intersection_Observer_API
    const el = document.querySelector('.main-header')
    const observer = new IntersectionObserver(([e]) => { this.sticked = e.intersectionRatio < 1 }, { threshold: [1] })
    observer.observe(el)
  }
}
</script>

<style lang="scss" scoped>
.main-header {
  position: sticky;
  top: -1px;
  background: $white;
  z-index: 10;
  .main-menu {
    @include mediaQueries('margin', (medium: 0 27px, large: 0 47px, xlarge: 0 55px));
    border-bottom: 1px solid $textlightgrey;
  }

  &.sticked {
    .main-menu {
      @include mediaQueries('padding', (medium: 0 27px, large: 0 47px, xlarge: 0 55px));
      margin: 0;
    }
  }
}
</style>
